import React, { useEffect, useState } from "react";

import { eachDayOfInterval, max, min } from "date-fns";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi2";
import { getGeocode, getLatLng } from "use-places-autocomplete";

import CruisingShowroomsMap from "@app/modules/cruising/components/map";
import { CruisingShowroomSingleForm } from "@app/modules/cruising/components/showroom/single-form";
import ShowroomsTable from "@app/modules/cruising/components/showroom/table";
import { ShowroomsTableState } from "@app/modules/cruising/components/showroom/table.helper";
import ShowroomUpload from "@app/modules/cruising/components/showroom/upload";
import TripPlanification from "@app/modules/cruising/components/trip-planification";
import { Cruising } from "@app/modules/cruising/types";
import {
  GooglePlaceDetailsResult,
  findCity,
  findElementInAddressComponents,
} from "@components/data-entry/AddressAutocompleteResultMapper";
import Button from "@components/data-entry/Button";
import Loading from "@components/feedback/Loading";
import Modal, { useModal } from "@components/feedback/Modal";
import { GetCruisingShowroomsEndpoint } from "@services/api/cruising/get-showrooms";
import { OptimizeCruisingShowroomsEndpoint } from "@services/api/cruising/optimize";

function parseStoredShowroom(
  showroom: GetCruisingShowroomsEndpoint.OutputItem,
): ShowroomsTableState.Row {
  return {
    id: showroom.id,
    lat: showroom.latitude,
    lng: showroom.longitude,
    address: {
      city: showroom.city,
      countryCode: showroom.countryCode,
      formattedAddress: showroom.formattedAddress,
      postalCode: showroom.postalCode,
      addressComponents: {},
    },
    name: showroom.name,
    from: min(showroom.dates),
    to: max(showroom.dates),
    startTime: showroom.openingHour,
    endTime: showroom.closingHour,
    duration: showroom.duration,
  };
}

export default function PlanMyTripPage() {
  const { t } = useTranslation();

  // init
  const modalState = useModal();
  const [tripPlan, setTripPlan] = useState<Cruising.TripPlanification>([]);
  const showroomsTable = ShowroomsTableState.useState({
    initialShowrooms: [],
  });
  const { dispatch } = showroomsTable;
  const { data: savedShowrooms, isPending } =
    GetCruisingShowroomsEndpoint.useHook();

  useEffect(() => {
    if (!isPending && savedShowrooms) {
      dispatch({
        type: "setShowrooms",
        showrooms: savedShowrooms.map(parseStoredShowroom),
      });
    }
  }, [isPending, savedShowrooms, dispatch]);

  const optimizeCruisingShowroomsMutation =
    OptimizeCruisingShowroomsEndpoint.useHook();

  const handleSingleFormSubmit = async (
    values: CruisingShowroomSingleForm.FormDataValidated,
  ) => {
    showroomsTable.dispatch({
      type: "addShowroom",
      showroom: values,
    });
    modalState.close();
  };

  const onFileUpload = (rows: Cruising.NewShowroom[]) => {
    showroomsTable.dispatch({
      type: "setShowrooms",
      showrooms: rows,
    });
  };

  const handleSaveCruisingShowrooms = async () => {
    const showroomsWithLatLng: OptimizeCruisingShowroomsEndpoint.Input =
      await Promise.all(
        showroomsTable.state.showrooms.map(async (s) => {
          if (ShowroomsTableState.isRowWithGeocode(s)) {
            return {
              id: s.id,
              name: s.name,
              dates: eachDayOfInterval({ start: s.from, end: s.to }),
              openingHour: s.startTime,
              closingHour: s.endTime,
              formattedAddress: s.address.formattedAddress,
              postalCode: s.address.postalCode,
              city: s.address.city,
              countryCode: s.address.countryCode,
              latitude: s.lat,
              longitude: s.lng,
              duration: s.duration,
            };
          }

          //! @Todo: use the new Places / Geocode API for this
          const geocoderResult = await getGeocode({
            address: s.address.formattedAddress,
          });
          const latLng = await getLatLng(geocoderResult[0]);

          const finder = findElementInAddressComponents(
            geocoderResult[0]
              .address_components as unknown as GooglePlaceDetailsResult["addressComponents"],
          );
          return {
            name: s.name,
            dates: eachDayOfInterval({ start: s.from, end: s.to }),
            openingHour: s.startTime,
            closingHour: s.endTime,
            postalCode: finder("postal_code") || "",
            city: findCity(finder),
            countryCode: finder("country") || "",
            formattedAddress: geocoderResult[0].formatted_address,
            latitude: latLng.lat,
            longitude: latLng.lng,
            duration: s.duration,
          };
        }),
      );

    optimizeCruisingShowroomsMutation
      .mutateAsync(showroomsWithLatLng)
      .then((optimization) => {
        setTripPlan(optimization.result);
      });
  };

  const tableHasErrors = ShowroomsTableState.tableHasErrors(
    showroomsTable.state,
  );

  return (
    <div className="grid grid-cols-12 w-full h-full no-scrollbar">
      <main className="col-span-12 md:col-span-8 p-4 flex flex-col gap-4 ">
        {tripPlan.length === 0 ? (
          <>
            <h3 className="font-bold text-lg">
              {t("Cruising.PlanMyTripPage.heading")}
            </h3>
            <div className="flex items-end justify-between">
              <p>{t("Cruising.PlanMyTripPage.explanations")}</p>
              {/* <ShowroomUpload onFileUploadOk={onFileUpload} /> */}
              <ShowroomUpload onFileUploadOk={onFileUpload} />
            </div>
            <ShowroomsTable {...showroomsTable} />

            <Button
              theme="SECONDARY"
              onClick={modalState.open}
              className="flex justify-center"
            >
              <HiPlus className="w-6 h-6" />
            </Button>

            <Modal
              title={t("Cruising.PlanMyTripPage.add-showroom")}
              state={modalState}
            >
              <CruisingShowroomSingleForm.Component
                onSubmit={handleSingleFormSubmit}
              />
            </Modal>
          </>
        ) : (
          <CruisingShowroomsMap
            showrooms={tripPlan.flatMap(({ tour }) =>
              tour.flatMap((appt) => ({
                lat: appt.appointement.coordinates[0],
                lng: appt.appointement.coordinates[1],
                name: appt.appointement.showroom,
              })),
            )}
          />
        )}
      </main>
      <aside className="m-4 col-span-12 md:col-span-4">
        <Button
          renderIf={tripPlan.length > 0}
          theme="PRIMARY"
          className="w-full flex justify-center mb-8"
          disabled={showroomsTable.state.showrooms.length < 2}
          onClick={() => setTripPlan([])}
        >
          {t("Cruising.PlanMyTripPage.redo-itinerary")}
        </Button>
        {tripPlan.length === 0 ? (
          <div className="w-full h-full flex flex-col justify-center items-center bg-primaryLightestGrey p-4">
            {optimizeCruisingShowroomsMutation.isPending ? (
              <Loading type="screen" />
            ) : (
              <>
                <p className="mb-8">
                  {t("Cruising.PlanMyTripPage.add-showrooms-first")}
                </p>

                <Button
                  theme="PRIMARY"
                  className="w-full flex justify-center"
                  disabled={
                    showroomsTable.state.showrooms.length < 2 || tableHasErrors
                  }
                  onClick={handleSaveCruisingShowrooms}
                >
                  {t("Cruising.PlanMyTripPage.plan-my-trip")}
                </Button>
              </>
            )}
          </div>
        ) : (
          <TripPlanification
            tripPlan={tripPlan}
            showrooms={showroomsTable.state.showrooms}
          />
        )}
      </aside>
    </div>
  );
}
