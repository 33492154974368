import addressAutocompleteResultMapper, {
  fetchSuggestions,
  getDetails,
} from "@components/data-entry/AddressAutocompleteResultMapper";
import { Address } from "@models/types/Address";

export default async function validateAddressWithGoogleMaps(
  address: string,
): Promise<Address> {
  let newAddress = {
    city: "",
    postalCode: "",
    countryCode: "",
    formattedAddress: address,
  };
  try {
    const result = await fetchSuggestions(address);

    const details = await getDetails(
      result.suggestions[0].placePrediction.placeId,
    );
    newAddress = addressAutocompleteResultMapper(details);
  } catch (e) {
    console.error(e);
  }
  return newAddress;
}
