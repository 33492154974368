import { useEffect, useState } from "react";

import { Address } from "@models/types/Address";

export async function getDetails(placeId: string) {
  return fetch(
    `https://places.googleapis.com/v1/places/${placeId}?fields=address_components,formattedAddress`,
    {
      headers: {
        "Content-Type": "application/json",
        "X-Goog-Api-Key": "AIzaSyD8aE-5Twhlv7xce5EdGNsvXA2_uD7y2cU",
      },
    },
  ).then((res) => res.json());
}

export type PlacesAutocompleteSuggestion = {
  placePrediction: {
    placeId: string;
    text: { text: string };
  };
};

export async function fetchSuggestions(
  input: string,
): Promise<{ suggestions: PlacesAutocompleteSuggestion[] }> {
  return fetch(`https://places.googleapis.com/v1/places:autocomplete`, {
    method: "POST",
    body: JSON.stringify({
      input,
    }),
    headers: {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": "AIzaSyD8aE-5Twhlv7xce5EdGNsvXA2_uD7y2cU",
    },
  }).then((res) => res.json());
}

export function usePlacesAutocomplete(inputValue: string) {
  const [suggestions, setSuggestions] = useState<
    {
      placePrediction: {
        placeId: string;
        text: { text: string };
      };
    }[]
  >([]);

  useEffect(() => {
    const timer = setTimeout(
      () =>
        fetchSuggestions(inputValue).then((data) => {
          setSuggestions(data.suggestions || []);
        }),
      1000,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  return { suggestions, clearSuggestions: () => setSuggestions([]) };
}

export type GooglePlaceDetailsResult = {
  formattedAddress: string;
  addressComponents: {
    languageCode: string;
    longText: string;
    shortText: string;
    types: string[];
  }[];
};

type ElementType =
  | "country"
  | "postal_code"
  | "postal_town"
  | "locality"
  | "sublocality"
  | "administrative_area_level_2";

// TODO : check what information are mandatories
export const findElementInAddressComponents =
  (address_components: GooglePlaceDetailsResult["addressComponents"]) =>
  (type: ElementType): string | undefined => {
    const element = address_components.find((a) => a.types.includes(type));
    return element?.shortText;
  };

export const findCity = (
  finder: (type: ElementType) => string | undefined,
): string => {
  let city = finder("locality");
  if (city === undefined) {
    city = finder("postal_town");
  }
  if (city === undefined) {
    city = finder("sublocality");
  }
  if (city === undefined) {
    city = finder("administrative_area_level_2");
  }
  if (city === undefined) {
    throw new Error(
      "google API result should have [locality, postal_town, sublocality, administrative_area_level_2] in address_components",
    );
  }
  return city;
};

const addressAutocompleteResultMapper = (
  googlePlaceResult: GooglePlaceDetailsResult | string,
): Address => {
  if (typeof googlePlaceResult === "string") {
    throw new Error(
      `google API returning "${googlePlaceResult}" as string without address components`,
    );
  }
  if (!googlePlaceResult.formattedAddress) {
    throw new Error("google API result is missing formatted_address");
  }
  if (!googlePlaceResult.addressComponents) {
    throw new Error("google API result is missing address_components");
  }
  const finder = findElementInAddressComponents(
    googlePlaceResult.addressComponents,
  );
  const countryCode = finder("country");
  if (!countryCode) {
    throw new Error(
      "google API result should have country in address_components",
    );
  }
  return {
    formattedAddress: googlePlaceResult.formattedAddress,
    postalCode: finder("postal_code") || "unknown",
    city: findCity(finder),
    countryCode,
    addressComponents: googlePlaceResult.addressComponents,
  };
};

export default addressAutocompleteResultMapper;
